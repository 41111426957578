import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Select,
  Tag,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  DeleteTwoTone,
  AppstoreOutlined,
  QrcodeOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import {
  getDiningTables,
  createDiningTables,
  updateDiningTables,
  deleteDiningTables,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import { getBranches } from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import { ADDON_QR_ORDERING } from "../../../configs/constants";

const Tables = () => {
  const { authUser } = useContext(AppContext);
  const ADD_TABLE = "Add Table";
  const ADD_DRIVE_THRU = "Add Drive-through";
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [branchesData, setBranchesData] = useState([]);
  const [tablesData, setTablesData] = useState([]);
  const [modalTitle, setModalTitle] = useState(ADD_TABLE);
  const [searchParams, setSearchParams] = useState([]);
  const [tableForm] = Form.useForm();
  const [searchFormRef] = Form.useForm();

  const { t } = useTranslation();

  const is_QR_addon_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_QR_ORDERING
  );

  useEffect(() => {
    getBranches()
      .then((response) => {
        setBranchesData(response.data.data.branches);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    setIsDataLoading(true);
    getDiningTables(searchParams)
      .then((response) => {
        setTablesData(response.data.data.dining_tables);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData, searchParams]);

  const showTableModal = () => {
    // Show Modal
    setModalVisible(true);
    // Set Modal Title
    setModalTitle(t("table_add"));
    // Explicitly reset Form Fields before setting them for edit
    // This ensures the form is in a clean state before being repopulated
    tableForm.resetFields();

    // Set Form Fields
    tableForm.setFieldsValue({
      is_drive_thru: 0,
    });
  };

  const showDriveThruModal = () => {
    // Show Modal
    setModalVisible(true);
    // Set Modal Title
    setModalTitle(t("drive_thru_add"));
    // Explicitly reset Form Fields before setting them for edit
    // This ensures the form is in a clean state before being repopulated
    tableForm.resetFields();

    // Set Form Fields
    tableForm.setFieldsValue({
      is_drive_thru: 1,
    });
  };

  const handleAPIResponse = (response, type) => {
    let data = response.data || response.response.data;
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_TABLE) {
        NotificationWithIcon(
          "success",
          t("table_added"),
          t("table_added_successfully")
        );
      } else if (type === ADD_DRIVE_THRU) {
        NotificationWithIcon(
          "success",
          t("drive_thru_added"),
          t("drive_thru_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("entity_updated"),
          t("entity_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon("error", t("something_went_wrong"), data.message);
    }
    // Reset Form Fields
    tableForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    tableForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        if (modalTitle === ADD_TABLE || modalTitle === t("table_add")) {
          createDiningTables(values)
            .then((response) => handleAPIResponse(response, ADD_TABLE))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else if (
          modalTitle === ADD_DRIVE_THRU ||
          modalTitle === t("drive_thru_add")
        ) {
          createDiningTables(values)
            .then((response) => handleAPIResponse(response, ADD_DRIVE_THRU))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateDiningTables(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (id) => {
    // Find branch from DataArray
    const table = tablesData.find((table) => table.id === id);

    // Ensure values are not undefined
    const name = table.name || ""; // Default to empty string if undefined
    const branchId = table.branch?.id || ""; // Default to empty string if undefined

    // Set Modal Title
    setModalTitle(t("edit") + " : " + name);

    // Show Modal
    setModalVisible(true);

    // Explicitly reset Form Fields before setting them for edit
    // This ensures the form is in a clean state before being repopulated
    tableForm.resetFields();

    // Set Form Fields
    tableForm.setFieldsValue({
      id: table.id,
      name: name,
      branch_id: branchId,
      is_drive_thru: table.is_drive_thru,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    tableForm.resetFields();
    setModalVisible(false);
  };

  const handleDelete = (id) => {
    deleteDiningTables(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("entity_deleted"),
            t("entity_deleted_successfully")
          );
        } else {
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "tables",
      breadcrumbName: t("menu_tables"),
      icon: <AppstoreOutlined />,
    },
  ];

  const ColumnsData = [
    {
      title: "#",
      align: "center",
      width: "50px",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("name"),
      render: (record) => {
        return (
          <>
            {record.name}{" "}
            {record.is_drive_thru ? (
              <Tag color="blue">{t("is_drive_thru")}</Tag>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: t("branch"),
      dataIndex: "branch",
      render: (branch) => {
        return branch.name;
      },
    },
    {
      title: t("col_action"),
      align: "center",
      width: "140px",
      render: (record) => {
        return (
          <>
            {is_QR_addon_active && (
              <Button
                type="link"
                title={t("qr")}
                href={record.qr_code_url}
                size="small"
                target="_blank"
              >
                <QrcodeOutlined />
              </Button>
            )}
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handleDelete(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.filter_by_branch_id) {
      search_params = {
        branch_id: values.filter_by_branch_id,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-report-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="filter_by_branch_id" label={t("branch")}>
          <Select
            style={{ width: "200px" }}
            options={branchesData.map((branch) => {
              return {
                label: branch.name,
                value: branch.id,
              };
            })}
          ></Select>
        </Form.Item>
        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_tables")}
          ghost={false}
          footer={searchForm}
          extra={[
            <Button
              key="open-add-DriveThru-modal"
              type="primary"
              onClick={showDriveThruModal}
            >
              + {t("drive_thru_add")}
            </Button>,
            <Button
              key="open-add-store-modal"
              type="primary"
              onClick={showTableModal}
            >
              + {t("table_add")}
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={ColumnsData}
          dataSource={tablesData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_TABLE || modalTitle === t("table_add")
              ? t("table_add")
              : modalTitle === ADD_DRIVE_THRU ||
                modalTitle === t("drive_thru_add")
              ? t("drive_thru_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-store-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={tableForm}
        >
          <Form.Item
            name="name"
            label={t("name")}
            rules={[
              {
                required: true,
                message: t("validate_name_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="branch_id"
            label={t("branch")}
            rules={[
              {
                required: true,
                message: t("validate_branch_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              allowClear
              options={branchesData.map((branch) => {
                return {
                  label: branch.name,
                  value: branch.id,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="is_drive_thru" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Tables;
