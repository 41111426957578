import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Radio,
  InputNumber,
  message,
  Button,
  Input,
  Checkbox,
  Spin,
  Alert,
} from "antd";
import { topupBalance } from "../network/network";
import {
  PAYMENT_BRAND_VISA,
  PAYMENT_BRAND_MASTER,
  PAYMENT_BRAND_MADA,
  PAYMENT_BRAND_STCPAY,
} from "../configs/constants";
import { useTranslation } from "react-i18next";
import NotificationWithIcon from "./NotificationWithIcon";

const TopUpPaymentModal = ({
  paymentModalVisible,
  setPaymentModalVisible,
  requiredBalance,
}) => {
  const [paymentFormLoading, setPaymentFormLoading] = useState(false);
  const [paymentBrand, setPaymentBrand] = useState(false);
  const [amount, setAmount] = useState(false);
  const [STCPAY_reference_id, setSTCPAY_reference_id] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [acceptCheckbox, setAcceptCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (paymentBrand && amount && amount > 0) {
      if (paymentBrand !== PAYMENT_BRAND_STCPAY) {
        setPaymentFormLoading(true);
        topupBalance({
          amount: amount,
          brand: paymentBrand,
        }).then((response) => {
          let data = response.data || response.response.data;
          if (data.result) {
            const script = document.createElement("script");
            if (process.env.REACT_APP_MODE === "LIVE") {
              script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${response.data.id}`;
            } else {
              script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${response.data.id}`;
            }
            script.async = true;

            document.body.appendChild(script);

            let brand = "VISA";
            if (paymentBrand === PAYMENT_BRAND_MADA) {
              brand = "MADA";
            } else if (paymentBrand === PAYMENT_BRAND_MASTER) {
              brand = "MASTER";
            }

            const form = document.createElement("form");
            //   form.action = process.env.REACT_APP_ROOT + "/payments";
            form.setAttribute("class", "paymentWidgets");
            form.setAttribute("data-brands", brand);
            document.querySelector("#payment_form").innerHTML = "";
            document.querySelector("#payment_form").appendChild(form);
            setTimeout(function () {
              setPaymentFormLoading(false);
            }, 2000);
          } else {
            message.error(data.message);
          }
        });
      } else {
        document.querySelector("#payment_form").innerHTML = "";
      }
    }
  }, [paymentBrand, amount]);

  const handleConfirm = () => {
    if (!acceptCheckbox) {
      message.error(t("check_confirmation_checkbox"));
    } else {
      if (paymentBrand && acceptCheckbox && STCPAY_reference_id && amount > 0) {
        setLoading(true);
        topupBalance({
          amount: amount,
          brand: paymentBrand,
          stcpay_reference_id: STCPAY_reference_id,
        })
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              setPaymentModalVisible(false);
              setConfirmationModalVisible(false);
              // Success
              NotificationWithIcon(
                "success",
                t("topup_request_received"),
                t("topup_request_received_successfully")
              );
            } else {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                data.message
              );
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <Modal
        title={t("payment_modal")}
        open={paymentModalVisible}
        onCancel={() => setPaymentModalVisible(false)}
        footer={null}
        bodyStyle={{ padding: "20px" }}
      >
        <p style={{ fontWeight: "bold" }}>{t("enter_topup_amount")}:</p>
        <InputNumber
          style={{ width: "100%" }}
          min={1}
          onChange={(value) => setAmount(value)}
          value={amount}
        />
        {requiredBalance > 0 && (
          <Alert
            message={t("minimum_topup_amount_required") + " " + requiredBalance}
            type="warning"
            showIcon
          />
        )}
        <br />
        <br />
        {amount >= requiredBalance && (
          <>
            <p style={{ fontWeight: "bold" }}>{t("select_payment_method")}:</p>
            <Form.Item>
              <Radio.Group
                onChange={(e) => {
                  setPaymentBrand(e.target.value);
                  setPaymentFormLoading(false);
                }}
                size="small"
              >
                <Radio.Button
                  style={{ height: "auto" }}
                  value={PAYMENT_BRAND_MADA}
                >
                  <img
                    src="/images/payment_type_mada.png"
                    alt="Payment Type MADA"
                  />
                </Radio.Button>

                <Radio.Button
                  style={{ height: "auto" }}
                  value={PAYMENT_BRAND_VISA}
                >
                  <img
                    src="/images/payment_brand_visa.png"
                    alt="Payment Type Visa"
                  />
                </Radio.Button>

                <Radio.Button
                  style={{ height: "auto" }}
                  value={PAYMENT_BRAND_MASTER}
                >
                  <img
                    src="/images/payment_brand_master.png"
                    alt="Payment Type Mastercard"
                  />
                </Radio.Button>

                <Radio.Button
                  style={{ height: "auto" }}
                  value={PAYMENT_BRAND_STCPAY}
                >
                  <img
                    src="/images/payment_type_stc.png"
                    alt="Payment Type STC pay"
                  />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <br />
          </>
        )}

        <div style={{ display: paymentFormLoading ? "flex" : "none" }}>
          <img
            src="/loader.gif"
            alt="Loading..."
            style={{ width: "64px", margin: "20px auto" }}
          />
        </div>

        <div
          id="payment_form"
          style={{ display: !paymentFormLoading ? "block" : "none" }}
        ></div>

        {paymentBrand === PAYMENT_BRAND_STCPAY && (
          <>
            <p style={{ fontWeight: "bold" }}>{t("enter_reference_no")}:</p>
            <Input
              style={{ width: "100%" }}
              onChange={(e) => setSTCPAY_reference_id(e.target.value)}
            />
            <br />
            <br />
            <Button
              type="primary"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                width: "100%",
              }}
              disabled={!STCPAY_reference_id}
              onClick={() => {
                setAcceptCheckbox(false);
                setConfirmationModalVisible(true);
              }}
            >
              {t("submit")}
            </Button>
          </>
        )}
      </Modal>
      <Modal
        title={t("confirmation")}
        open={confirmationModalVisible}
        onCancel={() => setConfirmationModalVisible(false)}
        onOk={handleConfirm}
      >
        <Checkbox
          onChange={(e) => {
            setAcceptCheckbox(e.target.checked);
          }}
          defaultChecked={false}
          checked={acceptCheckbox}
        >
          I hereby declare that the STC pay reference id provided in this form
          is accurate and complete. Your balance will be show on your dashboard
          with-in one working day.
        </Checkbox>
      </Modal>
    </Spin>
  );
};

export default TopUpPaymentModal;
