import React, { useContext, useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Card,
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Select,
  Popconfirm,
  DatePicker,
  Popover,
  message,
  InputNumber,
} from "antd";
import {
  HomeOutlined,
  BarChartOutlined,
  SettingOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
  DeleteTwoTone,
  ProfileOutlined,
} from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import {
  getStockAdjustments,
  createStockAdjustments,
  getProductStockByBranch,
} from "../../../network/network";
import TextArea from "antd/lib/input/TextArea";
import { AppContext } from "../../../context/AppContextProvider";
import { getBranches } from "../../../network/network";
import moment from "moment";
import NotificationWithIcon from "../../../components/NotificationWithIcon";

const StockAdjustment = () => {
  const { appConfig } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [stockAdjustmentData, setStockAdjustmentData] = useState([]);
  const [adjustmentData, setAdjustmentData] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [branchId, setBranchId] = useState(0);
  const [productsList, setProductsList] = useState([]);
  const [reloadTableData, setReloadTableData] = useState(false);
  const { t } = useTranslation();
  const [adjustmentForm] = Form.useForm();
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setIsDataLoading(true);
    setStockAdjustmentData([]);
    getStockAdjustments({
      page: currentPage,
    })
      .then((response) => {
        setStockAdjustmentData(
          response.data.data.stock_adjustments.stock_adjustments
        );
        setPaginationData(response.data.data.stock_adjustments.pagination);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData, currentPage]);

  useEffect(() => {
    setIsDataLoading(true);
    getBranches()
      .then((response) => {
        setBranchesData(response.data.data.branches);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  useEffect(() => {
    branchId &&
      getProductStockByBranch(branchId).then((response) => {
        setProductsList(response.data.data.products);
      });
  }, [branchId]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/inventory/stock",
      breadcrumbName: t("menu_inventory"),
      icon: <BarChartOutlined />,
    },
    {
      path: "inventory/stock-adjustment",
      breadcrumbName: t("menu_stock_adjustment"),
      icon: <SettingOutlined />,
    },
  ];

  const tableColumns = [
    {
      title: t("col_date"),
      dataIndex: "date_time",
      render: (date_time) => {
        return date_time ? moment(date_time).format("YYYY-MM-DD") : "-";
      },
    },
    {
      title: t("reference_no"),
      dataIndex: "reference_no",
    },
    {
      title: t("note"),
      dataIndex: "note",
      render: (note) => {
        return (
          <>
            {note && (
              <Popover
                content={
                  <div style={{ maxWidth: "250px", textAlign: "center" }}>
                    {note}
                  </div>
                }
                trigger="click"
              >
                <ProfileOutlined
                  style={{ fontSize: "20px", color: "#8629ff" }}
                />
              </Popover>
            )}
          </>
        );
      },
    },
    {
      title: t("branch"),
      dataIndex: "branch",
      render: (branch) => {
        return branch.name;
      },
    },
    {
      title: t("col_created_by"),
      render: (record) => {
        return <>{record.created_by.name}</>;
      },
    },
    {
      title: t("col_quantity"),
      align: "center",
      dataIndex: "total_quantity",
      render: (total_quantity) => {
        return (
          <>
            <div
              style={{
                color: Math.sign(total_quantity) === 1 ? "#46c35f" : "#fc2c10",
              }}
            >
              {total_quantity}
            </div>
          </>
        );
      },
    },
  ];

  const productTableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("image"),
      width: "90px",
      render: (record) => {
        return (
          <>
            <img
              alt={record.product.name_en}
              src={
                record.product.image
                  ? record.product.image
                  : "/images/image-not-found.png"
              }
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          </>
        );
      },
    },
    {
      title: t("col_product"),
      render: (record) => {
        return (
          <>
            {appConfig.direction === "ltr"
              ? record.product.name_en
              : record.product.name}
          </>
        );
      },
    },
    {
      title: t("current_stock"),
      align: "center",
      dataIndex: "quantity",
    },

    {
      title: t("col_quantity"),
      width: "150px",
      render: (record) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                justifyContent: "space-between",
                backgroundColor: "#e8f0fa",
                borderRadius: "35rem",
                width: "150px",
              }}
            >
              <MinusCircleFilled
                style={{
                  color: record.qty > 1 ? "#6301f2" : "#d9d9d9",
                  fontSize: "32px",
                  cursor: record.qty > 1 ? "pointer" : "not-allowed",
                }}
                onClick={() =>
                  record.qty > 1 && adjustmentReducer(record, "dec")
                }
              />
              <InputNumber
                min={0}
                onChange={(quantity) =>
                  adjustmentReducer(record, "directInput", quantity)
                }
                value={record.qty}
              />
              <PlusCircleFilled
                style={{
                  color: "#6301f2",
                  fontSize: "32px",
                  cursor: "pointer",
                }}
                onClick={() => adjustmentReducer(record, "inc")}
              />
            </div>
          </>
        );
      },
    },
    {
      title: t("col_type"),
      width: "150px",
      render: (record) => {
        return (
          <>
            <Select
              defaultValue="addition"
              style={{
                width: 120,
              }}
              onChange={(e) => adjustmentReducer(record, e)}
              options={[
                {
                  value: "addition",
                  label: "Addition",
                },
                {
                  value: "subtraction",
                  label: "Subtraction",
                  disabled: record.qty > record.quantity ? true : false,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      title: t("action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Popconfirm
              title={t("warning_remove")}
              onConfirm={() => adjustmentReducer(record, "remove")}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const handleProductSelect = (value) => {
    const product = productsList.find(
      (product) => product.product.id === value
    );
    product.product.product_id = product.product.id;
    adjustmentReducer(product, "add");
    adjustmentForm.setFieldsValue({
      product: "",
    });
  };

  const adjustmentReducer = (product, action, quantity) => {
    let updatedAdjustment = JSON.parse(JSON.stringify(adjustmentData));
    let productId = product.product.product_id;
    let indexFound = updatedAdjustment.findIndex(
      (product) => product.product.product_id === productId
    );
    if (action === "add") {
      if (indexFound === -1) {
        product.qty = 1;
        product.adjustment_type = "addition";
        updatedAdjustment.push(product);
      } else {
        message.error(t("product_already_selected"));
      }
    } else if (action === "inc") {
      updatedAdjustment[indexFound].qty++;
    } else if (action === "dec") {
      updatedAdjustment[indexFound].qty--;
    } else if (action === "remove") {
      updatedAdjustment.splice(indexFound, 1);
    } else if (action === "addition" || action === "subtraction") {
      updatedAdjustment[indexFound].adjustment_type = action;
    } else if (action === "directInput") {
      updatedAdjustment[indexFound].qty = quantity;
    }
    setAdjustmentData(updatedAdjustment);
  };

  const handleAdd = () => {
    setModalVisible(true);
    // Reset Form Fields
    adjustmentForm.resetFields();
    setAdjustmentData([]);
    setBranchId(0);
  };

  const handleCancel = () => {
    // close modal
    setModalVisible(false);
    // Reset Form Fields
    adjustmentForm.resetFields();
    setAdjustmentData([]);
    setBranchId(0);
  };

  const handleAddEdit = () => {
    adjustmentForm
      .validateFields()
      .then((values) => {
        // Sending Request to API
        let transactionObj = {
          branch_id: values.branch_id,
          date_time: moment(values.date_time).format("YYYY-MM-DD HH:mm:ss"),
          note: values.note === undefined ? "" : values.note,
        };
        transactionObj.stocks = adjustmentData.map((item) => ({
          product_id: item.product.product_id
            ? item.product.product_id
            : item.product.id,
          quantity:
            item.adjustment_type === "subtraction" ? -item.qty : item.qty,
        }));

        createStockAdjustments(transactionObj)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              setReloadTableData(!reloadTableData);
              // Reset Form Fields
              adjustmentForm.resetFields();
              // Hide Modal
              setModalVisible(false);
              // Success
              NotificationWithIcon(
                "success",
                t("stock_adjustment_done"),
                t("stock_adjustment_done_successfully")
              );
            } else {
              // Error
              let errors = data.data.errors;
              if (errors && Object.keys(errors).length) {
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              }
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            // setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationData(pagination);
    setCurrentPage(pagination.current);
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_stock_adjustment")}
          ghost={false}
          extra={[
            <Button type="primary" onClick={handleAdd} key={1}>
              + {t("adjustment_add")}
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumns}
          dataSource={stockAdjustmentData}
          loading={isDataLoading}
          rowKey="id"
          pagination={{
            current: paginationData.current_page,
            pageSize: paginationData.per_page,
            total: paginationData.total,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
        />
      </Card>
      <Modal
        open={modalVisible}
        title="Add Adjustment"
        onOk={handleAddEdit}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleAddEdit}>
            {t("add")}
          </Button>,
        ]}
      >
        <Form
          name="add-Adjustment-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={adjustmentForm}
        >
          <Row gutter={[20, 20]}>
            <Col
              className="gutter-row"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                name="branch_id"
                label={t("col_branches")}
                rules={[
                  {
                    required: true,
                    message: t("validate_branch_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  allowClear
                  onChange={(e) => {
                    setBranchId(e);
                    setAdjustmentData([]);
                  }}
                  options={branchesData.map((branch) => {
                    return {
                      label: branch.name,
                      value: branch.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                name="date_time"
                label={t("col_date")}
                rules={[
                  {
                    required: true,
                    message: t("validate_date_required"),
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  disabledDate={(current) => {
                    return current && current < moment().add(-1, "day");
                  }}
                  format="YYYY-MM-DD"
                  allowClear
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {branchId ? (
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <Form.Item
                  name="product"
                  label={t("product_add")}
                  rules={[
                    adjustmentData.length < 1 && {
                      required: true,
                      message: t("validate_product_name_required"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    options={productsList.map((product) => {
                      return {
                        label:
                          appConfig.direction === "ltr"
                            ? `${product.product.name_en} (${product.quantity})`
                            : `${product.product.name} (${product.quantity})`,
                        value: product.product.id,
                      };
                    })}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="large"
                    onChange={handleProductSelect}
                  />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            {adjustmentData.length > 0 && (
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <Table
                  columns={productTableColumns}
                  dataSource={adjustmentData}
                  rowKey="id"
                  pagination={false}
                />
              </Col>
            )}
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
            >
              <Form.Item name="note" label={t("note")}>
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
            >
              <Form.Item name="id" style={{ display: "none" }}>
                <input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default StockAdjustment;
