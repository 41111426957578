import React, { useEffect, useState } from "react";
import { Card, Row, Col, Typography, Spin, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  DeploymentUnitOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { showAddons } from "../../../network/network";
import { useTranslation } from "react-i18next";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const Addons = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [addonData, setAddonData] = useState([]);
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    setIsDataLoading(true);
    showAddons()
      .then((response) => {
        setAddonData(response.data.data.addons);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "addons",
      breadcrumbName: t("menu_addons"),
      icon: <DeploymentUnitOutlined />,
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          title={t("menu_addons")}
          ghost={false}
          style={{ padding: "0" }}
        />
      </Card>
      <Spin spinning={isDataLoading}>
        <Row gutter={20}>
          {addonData.map((addon) => {
            return (
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={6}
                key={addon.id}
              >
                <div
                  style={{ position: "absolute", zIndex: "99", margin: "10px" }}
                >
                  {addon.is_subscribed && (
                    <Tag color="green">{t("addon_subscribed")}</Tag>
                  )}
                </div>
                <Card
                  onClick={() => {
                    navigate("/addons/" + addon.id);
                  }}
                  style={{ marginBottom: 20, cursor: "pointer" }}
                  cover={
                    <img
                      alt={addon.name}
                      src={
                        addon.image
                          ? addon.image
                          : "/images/image-not-found.png"
                      }
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "fill",
                      }}
                    />
                  }
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h3
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {addon.name}
                      </h3>
                      <div
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {addon.discount > 0 ? (
                          <>
                            <small>
                              <Text delete type="danger">
                                <CurrencyFormatter value={addon.price} />
                              </Text>
                            </small>
                            <span
                              style={{
                                color: "#8629ff",
                              }}
                            >
                              <CurrencyFormatter
                                value={addon.price - addon.discount}
                              />
                            </span>
                          </>
                        ) : addon.trial_validity_days > 0 ? (
                          addon.price !== "0.00" ? (
                            <>
                              <small>
                                <Text delete type="danger">
                                  <CurrencyFormatter value={addon.price} />
                                </Text>
                              </small>{" "}
                              <span
                                style={{
                                  color: "#8629ff",
                                }}
                              >
                                <CurrencyFormatter value={0} />
                              </span>
                            </>
                          ) : (
                            <span
                              style={{
                                color: "#8629ff",
                              }}
                            >
                              <CurrencyFormatter value={0} />
                            </span>
                          )
                        ) : (
                          <span
                            style={{
                              color: "#8629ff",
                            }}
                          >
                            <CurrencyFormatter value={addon.price} />
                          </span>
                        )}
                      </div>
                      <div
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("trial_days")} : {addon.trial_validity_days}
                      </div>
                    </div>
                    <PlusCircleOutlined
                      style={{
                        color: "#6301f2",
                        fontSize: "24px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    {addon.description.length > 120
                      ? addon.description.substring(0, 120) + "..."
                      : addon.description}
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Spin>
    </>
  );
};

export default Addons;
