import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { createTransactions } from "../../network/network";
import NotificationWithIcon from "../NotificationWithIcon";

const SaveOrder = ({ diningTableId, waiterId, items, reloadCallback }) => {
  const { t } = useTranslation();

  const handleCheckout = () => {
    let transactionObj = {
      transaction_status: 2,
    };
    transactionObj.items = JSON.stringify(
      items.map((item) => ({
        id: item.id,
        qty: item.quantity,
        price: item.price,
      }))
    );
    if (diningTableId) {
      transactionObj.dining_table_id = diningTableId;
    }
    if (waiterId) {
      transactionObj.waiter_id = waiterId;
    }
    createTransactions(transactionObj).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        // Success
        NotificationWithIcon(
          "success",
          t("order_added"),
          t("order_added_successfully")
        );
        reloadCallback();
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data.message);
      }
    });
  };

  return (
    <>
      <Button
        size="large"
        style={{
          borderColor: "#faad14",
          backgroundColor: "#fffbe6",
          textAlign: "center",
          fontWeight: "bold",
          width: "100%",
        }}
        onClick={handleCheckout}
      >
        {t("add_order")}
      </Button>
    </>
  );
};

export default SaveOrder;
