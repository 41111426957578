import { useContext } from "react";
import { AppContext } from "../../../context/AppContextProvider";

import { Button, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { updateAuthDetails } from "../../../network/network";
import { useNavigate } from "react-router-dom";
import { SIDEBAR_MENU_KEY_STORE_CONFIGURATION } from "../../../configs/constants";

const RegisterConfirmed = () => {
  const { authUser, setAuthUser, setActiveMenu } = useContext(AppContext);

  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleSkip = () => {
    updateAuthDetails().then((response) => {
      if (response.data && response.data.success === true) {
        setAuthUser(response.data.data.user);
      } else {
        let errorMessages = Object.values(response?.data?.data?.errors);
        errorMessages.forEach(function (key, value) {
          let temp = Object.values(key);
          message.error(temp[0]);
        });
      }
    });
  };
  const handleUpdate = () => {
    navigate("/store-configuration");
    setActiveMenu(SIDEBAR_MENU_KEY_STORE_CONFIGURATION);
  };

  return (
    <>
      <div
        style={{
          textAlign: "center",
          padding: "30px",
          backgroundColor: "#fff",
        }}
      >
        <h1>
          Hey {authUser.name}, {t("account_successfully_created")}
        </h1>
        <img src="/images/check_mark.gif" alt="AnyPOS Logo" />
        <p style={{ marginTop: "10px", marginBottom: "20px" }}>
          {t("details_activate_company")}
        </p>
        <Space>
          <Button onClick={handleSkip}>{t("skip")}</Button>
          <Button type="primary" onClick={handleUpdate}>
            {t("fill_the_details")}
          </Button>
        </Space>
      </div>
    </>
  );
};

export default RegisterConfirmed;
