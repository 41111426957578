import React, { useContext, useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Card, Table, Button, Tag } from "antd";
import {
  HomeOutlined,
  BarChartOutlined,
  SettingOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { getStockTransfers } from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import moment from "moment";
import {
  INVENTORY_REQUEST_CANCELLED,
  INVENTORY_REQUEST_COMPLETED,
  INVENTORY_REQUEST_PENDING,
  INVENTORY_REQUEST_REJECTED,
  USER_TYPE_BUSINESS_OWNER,
} from "../../../configs/constants";
import { useNavigate } from "react-router-dom";
import DirectTransfer from "./DirectTransfer";
import RequestTransfer from "./RequestTransfer";

const StockTransfer = () => {
  const { authUser } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [stockTransferData, setStockTransferData] = useState([]);
  const [reloadTableData, setReloadTableData] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    setIsDataLoading(false);
    setStockTransferData([]);
    getStockTransfers()
      .then((response) => {
        setStockTransferData(response.data.data.stock_transfers);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/inventory/stock",
      breadcrumbName: t("menu_inventory"),
      icon: <BarChartOutlined />,
    },
    {
      path: "inventory/stock-transfer",
      breadcrumbName: t("menu_stock_transfer"),
      icon: <SettingOutlined />,
    },
  ];

  const tableColumns = [
    {
      title: t("col_date"),
      dataIndex: "date_time",
      render: (date_time) => {
        return date_time ? moment(date_time).format("YYYY-MM-DD") : "-";
      },
    },
    {
      title: t("requested_quantity"),
      align: "center",
      dataIndex: "total_requested_quantity",
    },
    {
      title: t("approved_quantity"),
      align: "center",
      dataIndex: "total_approved_quantity",
    },
    {
      title: t("col_branch_from"),
      dataIndex: "branch_from",
      render: (branch_from) => {
        return branch_from.name;
      },
    },
    {
      title: t("col_branch_to"),
      dataIndex: "branch_to",
      render: (branch_to) => {
        return branch_to.name;
      },
    },
    {
      title: t("col_status"),
      render: (record) => {
        return (
          <>
            {record.status === INVENTORY_REQUEST_PENDING && (
              <Tag color="orange">{t("pending")}</Tag>
            )}
            {record.status === INVENTORY_REQUEST_COMPLETED && (
              <Tag color="green">{t("completed")}</Tag>
            )}
            {record.status === INVENTORY_REQUEST_CANCELLED && (
              <Tag color="red">{t("cancelled")}</Tag>
            )}
            {record.status === INVENTORY_REQUEST_REJECTED && (
              <Tag color="red">{t("rejected")}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("col_created_by"),
      render: (record) => {
        return <>{record.created_by.name}</>;
      },
    },
    {
      title: t("col_action"),
      align: "center",
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              style={{ padding: 2 }}
              onClick={() => {
                navigate("/inventory/stock-transfer/" + record.id);
              }}
            >
              <EyeTwoTone
                twoToneColor="#0000ff"
                title="View Details"
                style={{ fontSize: "20px" }}
              />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_stock_transfer")}
          ghost={false}
          extra={[
            (authUser.can_request_stock_transfer ||
              authUser.type === USER_TYPE_BUSINESS_OWNER) && (
              <RequestTransfer
                reloadCallback={() => setReloadTableData(!reloadTableData)}
                key={1}
              />
            ),
            authUser.type === USER_TYPE_BUSINESS_OWNER && (
              <DirectTransfer
                reloadCallback={() => setReloadTableData(!reloadTableData)}
                key={2}
              />
            ),
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumns}
          dataSource={stockTransferData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
    </>
  );
};

export default StockTransfer;
