import React from "react";
import { Button, message } from "antd";
import {
  getCategoryTemplateExport,
  getUnitsTemplateExport,
  getProductTemplateExport,
} from "../network/network";
import { useTranslation } from "react-i18next";

const DownloadImportTemplate = ({ type }) => {
  const PRODUCT = "product";
  const CATEGORY = "category";
  const UNIT = "unit";
  const { t } = useTranslation();

  const unitTemplateExport = () => {
    getUnitsTemplateExport().then((response) => {});
  };
  const categoryTemplateExport = () => {
    getCategoryTemplateExport().then((response) => {});
  };
  const productTemplateExport = () => {
    getProductTemplateExport()
      .then((response) => {
        if (response && response.request.status === 400) {
          console.log("Handle errors here");
          message.error("First upload units and categories please.");
        } else {
          console.log("File downloaded.");
        }
      })
      .catch((info) => {
        console.log(info.response);
      });
  };

  return (
    <>
      {type === PRODUCT && (
        <Button
          type="primary"
          onClick={() => {
            productTemplateExport();
          }}
        >
          {t("download_template")}
        </Button>
      )}
      {type === CATEGORY && (
        <Button
          type="primary"
          onClick={() => {
            categoryTemplateExport();
          }}
        >
          {t("download_template")}
        </Button>
      )}
      {type === UNIT && (
        <Button
          type="primary"
          onClick={() => {
            unitTemplateExport();
          }}
        >
          {t("download_template")}
        </Button>
      )}
    </>
  );
};

export default DownloadImportTemplate;
