import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import {
  DeleteTwoTone,
  EditOutlined,
  HomeOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { editTransaction } from "../../../network/network";

import { AppContext } from "../../../context/AppContextProvider";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import { TAX_PERCENTAGE } from "../../../configs/constants";
import NotificationWithIcon from "../../../components/NotificationWithIcon";

const EditOrder = () => {
  const { authUser, appConfig } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  let { orderId } = useParams();
  const { t } = useTranslation();
  const [editOrderForm] = Form.useForm();
  const { state } = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    state ? setData(state) : navigate("/orders/" + orderId);
  }, [navigate, orderId, state]);

  useEffect(() => {
    let categories = [{ id: 0, name: t("all"), name_ar: t("الجميع") }];
    let products = [];
    authUser.categories.forEach((category) => {
      if (category.products.length > 0) {
        categories.push({
          id: category.id,
          name: category.name,
          name_ar: category.name_ar,
        });
        products = [...products, ...category.products];
      }
    });
    setProductsData(products);
  }, [authUser, t]);

  useEffect(() => {
    editOrderForm.setFieldsValue({
      id: data.id,
      order_id: data.order_id,
    });
  }, [data, editOrderForm]);

  useEffect(() => {
    if (data && data.items && data.items.length > 0) {
      setCart(JSON.parse(JSON.stringify(data.items)));
    }
  }, [data]);

  useEffect(() => {
    let taxTotal = 0;
    let total = 0;
    let tax = 0;
    let subtotal = 0;
    let productPrice = 0;
    cart.forEach((product) => {
      productPrice = product.price;
      if (product.tax > 0) {
        tax = productPrice * TAX_PERCENTAGE;
        taxTotal += tax * product.quantity;
      }
      subtotal = productPrice * product.quantity;
      total = total + subtotal;
    });

    setTotalVAT(taxTotal);
    setTotalAmount(total + taxTotal);
  }, [cart, setTotalAmount, setTotalVAT]);

  const cartReducer = (product, action) => {
    let updatedCart = JSON.parse(JSON.stringify(cart));
    let productId = product.product_id;
    let indexFound = updatedCart.findIndex(
      (product) => product.product_id === productId
    );
    if (action === "add") {
      if (indexFound === -1) {
        product.quantity = 1;
        updatedCart.push(product);
      } else {
        updatedCart[indexFound].quantity++;
      }
    } else if (action === "inc") {
      updatedCart[indexFound].quantity++;
    } else if (action === "dec") {
      updatedCart[indexFound].quantity--;
    } else if (action === "remove") {
      updatedCart.splice(indexFound, 1);
    }
    setCart(updatedCart);
  };

  const handleProductSelect = (value) => {
    const product = productsData.find((product) => product.id === value);
    product.product_id = product.id;
    cartReducer(product, "add");
    editOrderForm.setFieldsValue({
      product: "",
    });
  };

  const tableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("image"),
      render: (record) => {
        return (
          <>
            <img
              alt={record.name_en}
              src={record.image ? record.image : "/images/image-not-found.png"}
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          </>
        );
      },
    },
    {
      title: t("col_product"),
      render: (record) => {
        return (
          <>{appConfig.direction === "ltr" ? record.name_en : record.name}</>
        );
      },
    },
    {
      title: appConfig.direction === "ltr" ? "Unit Price" : "سعر الوحدة",
      render: (record) => {
        return (
          <>
            <CurrencyFormatter value={record.price} />
          </>
        );
      },
    },
    {
      title: t("col_quantity"),
      render: (record) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                justifyContent: "space-between",
                backgroundColor: "#e8f0fa",
                borderRadius: "35rem",
                width: "100px",
              }}
            >
              <MinusCircleFilled
                style={{
                  color: record.quantity > 1 ? "#6301f2" : "#d9d9d9",
                  fontSize: "32px",
                  cursor: record.quantity > 1 ? "pointer" : "not-allowed",
                }}
                onClick={() =>
                  record.quantity > 1 && cartReducer(record, "dec")
                }
              />
              <span>{record.quantity}</span>
              <PlusCircleFilled
                style={{
                  color: "#6301f2",
                  fontSize: "32px",
                  cursor: "pointer",
                }}
                onClick={() => cartReducer(record, "inc")}
              />
            </div>
          </>
        );
      },
    },
    {
      title:
        appConfig.direction === "ltr"
          ? "Price including tax"
          : "السعر مع الضريبة",
      render: (record) => {
        return (
          <>
            <CurrencyFormatter
              value={
                record.quantity * record.price * TAX_PERCENTAGE +
                record.quantity * record.price
              }
            />
          </>
        );
      },
    },
    {
      title: t("action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => cartReducer(record, "remove")}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/orders",
      breadcrumbName: t("menu_orders"),
      icon: <ReconciliationOutlined />,
    },
    {
      path: "edit-orders",
      breadcrumbName: t("menu_edit_order"),
      icon: <EditOutlined />,
    },
  ];

  const handleEditTransaction = () => {
    editOrderForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        values.items = JSON.stringify(
          cart.map((item) => ({
            id: item.product_id,
            qty: item.quantity,
            price: item.price,
          }))
        );

        editTransaction(values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("order_updated"),
                t("order_updated_successfully")
              );
              navigate("/orders/" + orderId);
            } else {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                data.message
              );
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          title={t("menu_edit_order")}
          onBack={() => window.history.back()}
          ghost={false}
          style={{ padding: "0" }}
        />
      </Card>
      <Spin spinning={loading}>
        <Card>
          {data && (
            <Form
              name="edit-order-form"
              layout="vertical"
              onFinish={handleEditTransaction}
              style={{ width: "100%" }}
              form={editOrderForm}
            >
              <Form.Item name="id" hidden={true}>
                <input />
              </Form.Item>
              <Row gutter={[20, 20]}>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <Form.Item name="order_id" label=<b>{t("order_id")}</b>>
                    <Input size="large" disabled={true} />
                  </Form.Item>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <Form.Item name="product" label=<b>{t("product_add")}</b>>
                    <Select
                      showSearch
                      allowClear
                      options={productsData.map((product) => {
                        return {
                          label:
                            appConfig.direction === "ltr"
                              ? `${product.name_en} (${product.category})`
                              : `${product.name} (${product.category_ar})`,
                          value: product.id,
                        };
                      })}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="large"
                      onChange={handleProductSelect}
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "8px",
                    }}
                  >
                    {appConfig.direction === "ltr" ? "Items" : "الصنف"}
                  </div>
                  <Table
                    columns={tableColumns}
                    dataSource={cart}
                    loading={loading}
                    rowKey="id"
                    pagination={false}
                  />
                  <div
                    style={{ border: "1px solid #d9d9d9", marginTop: "30px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      {appConfig.direction === "ltr"
                        ? "Total without tax"
                        : "الإجمالي بدون الضريبة"}
                      <span>
                        <CurrencyFormatter value={totalAmount - totalVAT} />
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      {appConfig.direction === "ltr"
                        ? "Total VAT"
                        : "مجموع ضریبة القیمة المضافة"}
                      <span>
                        <CurrencyFormatter value={totalVAT} />
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        color: "#8629ff",
                      }}
                    >
                      {appConfig.direction === "ltr"
                        ? authUser.is_vat_exempt
                          ? "Total"
                          : "Total with tax"
                        : authUser.is_vat_exempt
                        ? "الإجمالي"
                        : "الإجمالي مع الضريبة"}

                      <b>
                        <CurrencyFormatter value={totalAmount} />
                      </b>
                    </div>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <Form.Item style={{ textAlign: "center" }}>
                    <Space>
                      <Button
                        key="back"
                        onClick={() => navigate("/orders/" + orderId)}
                      >
                        {t("cancel")}
                      </Button>
                      <Button type="primary" htmlType="submit">
                        {t("submit")}
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Card>
      </Spin>
    </div>
  );
};

export default EditOrder;
