// Notification Types
export const NOTIFICATION_TYPE_INFO = 1;
export const NOTIFICATION_TYPE_SUCCESS = 2;
export const NOTIFICATION_TYPE_WARNING = 3;
export const NOTIFICATION_TYPE_ERROR = 4;

// TRANSACTION_TYPES
export const TRANSACTION_TYPE_CASH = 1;
export const TRANSACTION_TYPE_MADA = 2;
export const TRANSACTION_TYPE_STC = 3;
export const TRANSACTION_TYPE_CREDIT = 4;
export const TRANSACTION_TYPE_MULTI = 5;

export const transactionTypes = [
  { id: 1, text: "Cash" },
  // { id: 2, text: "MADA" },
  // { id: 3, text: "STC Pay" },
  { id: 4, text: "Card" },
  { id: 5, text: "Multi Payment" },
];
// Balance Deduction Type

export const BALANCE_DEDUCTION_TYPE_SUBSCRIPTION = 1;
export const BALANCE_DEDUCTION_TYPE_QRORDER = 2;

export const TAX_PERCENTAGE = 0.15;

// SUBSCRIPTION_PLANS
export const SUBSCRIPTION_PLAN_MONTHLY = 1;
export const SUBSCRIPTION_PLAN_QUARTERLY = 2;
export const SUBSCRIPTION_PLAN_YEARLY = 3;

// Plan Types
export const PLAN_TYPE_BASIC = 1;
export const PLAN_TYPE_PRO = 2;
export const PLAN_TYPE_DAILY = 3;

// Plan Monthly
export const PERIOD_MONTHLY = 1;
export const PERIOD_YEARLY = 2;
export const PERIOD_DAILY = 3;

// Addons billing cycle
export const BILLING_CYCLE_MONTHLY = 1;
export const BILLING_CYCLE_YEARLY = 2;
export const BILLING_CYCLE_DAILY = 3;

// REFUND_TYPE
export const REFUND_TYPE_FULL = 1;
export const REFUND_TYPE_PARTIAL = 2;

// User Types
export const USER_TYPE_MANAGER = 1; // manager for anypos admin portal
export const USER_TYPE_BUSINESS_OWNER = 2; // store owner
export const USER_TYPE_EMPLOYEE = 3; // store employee
export const USER_TYPE_ADMIN_STAFF = 4; // staff for anypos admin portal
export const USER_TYPE_SUPER_ADMIN = 5; // super admin for anypos admin portal

// Company Status
export const COMPANY_STATUS_ACTIVE = 1;
export const COMPANY_STATUS_KYC = 2;
export const COMPANY_STATUS_REVIEW = 3;
export const COMPANY_STATUS_SUBSCRIPTION_ENDED = 4;
export const COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW = 5;
export const COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED = 6;
export const COMPANY_STATUS_BLOCKED = 99;

// Invoice Status
export const INVOICE_STATUS_UNPAID = 0;
export const INVOICE_STATUS_CANCELLED = 1;
export const INVOICE_STATUS_PAID = 2;
export const INVOICE_STATUS_REFUNDED = 3;

// Payment Brand
export const PAYMENT_BRAND_VISA = 1;
export const PAYMENT_BRAND_MASTER = 2;
export const PAYMENT_BRAND_MADA = 3;
export const PAYMENT_BRAND_STCPAY = 4;

// Invoice Detail Type
export const INVOICE_DETAIL_TYPE_SUBSCRIPTION = 1;
export const INVOICE_DETAIL_TYPE_LICENSE = 2;
export const INVOICE_DETAIL_TYPE_DISCOUNT = 3;
export const INVOICE_DETAIL_TYPE_TAX = 4;
export const INVOICE_DETAIL_TYPE_DEVICE_PAYMENT = 5;
export const INVOICE_DETAIL_TYPE_TOPUP = 7;

// Invoice Type
export const INVOICE_TYPE_SUBSCRIPTION = 1;
export const INVOICE_TYPE_LICENSE = 2;

// Menu Keys
export const SIDEBAR_MENU_KEY_HOME = "home";
export const SIDEBAR_MENU_KEY_INVOICES = "invoices";
export const SIDEBAR_MENU_KEY_STORE_CONFIGURATION = "store-configuration";

// integration_type
export const ODOO = "Odoo";
export const XERO = "Xero";
export const ZOHO = "Zoho";

// Transaction Status
export const TRANSACTION_STATUS_PENDING = 1;
export const TRANSACTION_STATUS_INPROGRESS = 2;
export const TRANSACTION_STATUS_COMPLETED = 3;
export const TRANSACTION_STATUS_CANCELLED = 4;

// Transaction Order Source
export const transactionOrderSource = [
  { id: 1, text: "POS" },
  { id: 2, text: "Waiter" },
  { id: 3, text: "QR Order" },
  { id: 4, text: "Kiosk" },
];
// Addon Name
export const ADDON_QR_ORDERING = "QR Ordering";
export const ADDON_TABLE_MANAGEMENT = "Table Management";
export const ADDON_ORDER_MANAGEMENT = "Order Management";
export const ADDON_WAITER_MANAGEMENT = "Waiter Management";
export const ADDON_JOB_MANAGEMENT = "Job Management";
export const ADDON_STOCK_MANAGEMENT = "Stock";

//Stock Transfer Status
export const INVENTORY_REQUEST_PENDING = 0;
export const INVENTORY_REQUEST_COMPLETED = 1;
export const INVENTORY_REQUEST_CANCELLED = 2;
export const INVENTORY_REQUEST_REJECTED = 3;
