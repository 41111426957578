import "./Dashboard.css";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Menu, Layout, Alert, Button, Dropdown, Space } from "antd";
import {
  DownOutlined,
  UserOutlined,
  GlobalOutlined,
  ShopOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../context/AppContextProvider";
import AppRoutes, { ContentPages } from "../../routes";
import { Link } from "react-router-dom";
import StoreConfiguration from "../Content/StoreConfiguration/StoreConfiguration";
import Subscriptions from "../Content/Subscriptions/Subscriptions";
import { useTranslation } from "react-i18next";
import {
  updateAuthDetails,
  userLogout,
  getinvoices,
} from "../../network/network";
import { filterPagesByAddons } from "../../utils";
import Home from "../Content/Home/Home";
import {
  COMPANY_STATUS_ACTIVE,
  // COMPANY_STATUS_KYC,
  // COMPANY_STATUS_REVIEW,
  COMPANY_STATUS_BLOCKED,
  COMPANY_STATUS_SUBSCRIPTION_ENDED,
  COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW,
  COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED,
  INVOICE_STATUS_UNPAID,
  SIDEBAR_MENU_KEY_HOME,
  SIDEBAR_MENU_KEY_INVOICES,
  SIDEBAR_MENU_KEY_STORE_CONFIGURATION,
  USER_TYPE_BUSINESS_OWNER,
} from "../../configs/constants";
import { useNavigate } from "react-router-dom";
import Invoices from "../Content/Invoices/Invoices";

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  const {
    authUser,
    activeMenu,
    setActiveMenu,
    appConfig,
    setAppConfigWrapper,
    fullScreenPOS,
    isSiderCollapsed,
    setIsSiderCollapsed,
  } = useContext(AppContext);
  const [showPageOnly, setShowPageOnly] = useState(false);
  const [blockRouting, setBlockRouting] = useState(false);
  const [anyUnpaidInvoice, setAnyUnpaidInvoice] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState("");
  const { t } = useTranslation();
  let navigate = useNavigate();
  const windowWidth = useRef(window.innerWidth);

  useEffect(() => {
    if (authUser.company_status === COMPANY_STATUS_ACTIVE) {
      setBlockRouting(false);
      setShowPageOnly(false);
      // } else if (
      //   authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED
      // ) {
      //   // TODO: Allow 7 days, otherwise block screen to subscription
      //   setBlockRouting(false);
      //   setShowPageOnly(false);
    } else {
      // if (authUser.company_status === COMPANY_STATUS_KYC) {
      //   setBlockRouting(true);
      //   setShowPageOnly("STORE_CONFIGURATION");
      // } else
      // if (authUser.company_status === COMPANY_STATUS_REVIEW) {
      //   setBlockRouting(true);
      //   setShowPageOnly("HOME");
      // } else
      if (authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_ENDED) {
        setBlockRouting(true);
        setShowPageOnly("SUBSCRIPTION");
      } else if (
        authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW
      ) {
        setBlockRouting(true);
        setShowPageOnly("SUBSCRIPTION");
      } else if (authUser.company_status === COMPANY_STATUS_BLOCKED) {
        setBlockRouting(true);
        setShowPageOnly("INVOICES");
      } else if (
        authUser.company_status ===
        COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED
      ) {
        setShowPageOnly("INVOICES");
      }
    }

    setAppConfigWrapper(authUser.app_config);
    // eslint-disable-next-line
  }, [authUser.company_status]);

  useEffect(() => {
    if (authUser && authUser.type === USER_TYPE_BUSINESS_OWNER) {
      setTimeout(() => {
        getinvoices().then((response) => {
          let invoices = response.data.data.invoices || [];
          for (let i = 0; i < invoices.length; i++) {
            if (invoices[i].status === INVOICE_STATUS_UNPAID) {
              setAnyUnpaidInvoice(true);
              break;
            }
          }
        });
      }, 2000);
    }
  }, [authUser]);

  useEffect(() => {
    if (
      authUser &&
      Object.keys(appConfig).length > 0 &&
      JSON.stringify(authUser.app_config) !== JSON.stringify(appConfig)
    ) {
      const formData = new FormData();
      formData.append("app_config", JSON.stringify(appConfig));
      updateAuthDetails(formData).then((res) => {
        console.log("App Config Updated");
      });
    }
  }, [appConfig, authUser]);

  useEffect(() => {
    setDeviceWidth(windowWidth.current);
  }, [windowWidth]);

  const saveCurrentPageView = (key) => {
    setActiveMenu(key);
    localStorage.setItem(
      "last_visited_page",
      JSON.stringify({
        key: key,
        path: window.location.pathname,
      })
    );
  };

  const logoutUser = () => {
    userLogout().then(function () {
      localStorage.removeItem("last_visited_page");
      localStorage.removeItem("access_token");
      window.location = "/login";
    });
  };

  const UserOptions = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            logoutUser();
          }}
        >
          {t("logout")}
        </div>
      ),
    },
  ];

  const LangOptions = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "ltr" });
          }}
        >
          English
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "rtl" });
          }}
        >
          عربي
        </div>
      ),
    },
  ];

  return (
    <Layout>
      {!fullScreenPOS && (
        <Header
          style={{
            backgroundColor: "#ffffff",
            position: "sticky",
            top: 0,
            zIndex: 100,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            lineHeight: "unset",
            padding: "0 20px",
            boxShadow: "#0000005c 0px 0px 30px -10px",
            maxHeight: "65px",
            height: "100vh",
          }}
        >
          <Space size={20} style={{ flexGrow: "1", justifyContent: "start" }}>
            <Button
              onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
              shape="round"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0",
                height: "unset",
              }}
            >
              {appConfig.direction === "ltr" ? (
                isSiderCollapsed ? (
                  <MenuUnfoldOutlined
                    style={{
                      fontSize: "20px",
                      display: "inline-flex",
                      backgroundColor: "#f4e6ff",
                      padding: "4px",
                      borderRadius: "35rem",
                      margin: "2px",
                    }}
                  />
                ) : (
                  <MenuFoldOutlined
                    style={{
                      fontSize: "20px",
                      display: "inline-flex",
                      backgroundColor: "#f4e6ff",
                      padding: "4px",
                      borderRadius: "35rem",
                      margin: "2px",
                    }}
                  />
                )
              ) : isSiderCollapsed ? (
                <MenuFoldOutlined
                  style={{
                    fontSize: "20px",
                    display: "inline-flex",
                    backgroundColor: "#f4e6ff",
                    padding: "4px",
                    borderRadius: "35rem",
                    margin: "2px",
                  }}
                />
              ) : (
                <MenuUnfoldOutlined
                  style={{
                    fontSize: "20px",
                    display: "inline-flex",
                    backgroundColor: "#f4e6ff",
                    padding: "4px",
                    borderRadius: "35rem",
                    margin: "2px",
                  }}
                />
              )}
            </Button>
            <img
              src="/logo.png"
              alt="AnyPOS Logo"
              style={{
                width: "100%",
                maxWidth: "60px",
                height: "auto",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/home");
                setActiveMenu(SIDEBAR_MENU_KEY_HOME);
              }}
            />
            {authUser.business_name && (
              <Button
                shape="round"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0",
                  height: "unset",
                }}
                onClick={() => {
                  navigate("/store-configuration");
                  setActiveMenu(SIDEBAR_MENU_KEY_STORE_CONFIGURATION);
                }}
              >
                <ShopOutlined
                  style={{
                    fontSize: "20px",
                    display: "inline-flex",
                    backgroundColor: "#f4e6ff",
                    padding: "4px",
                    borderRadius: "35rem",
                    margin: "2px",
                  }}
                />
                <span style={{ margin: "0 8px" }}>
                  {authUser.business_name}
                </span>
              </Button>
            )}
          </Space>
          <Space size={20} style={{ flexGrow: "1", justifyContent: "center" }}>
            {process.env.REACT_APP_MODE !== "LIVE" && (
              <Alert
                message="Dev Server"
                type="warning"
                banner
                style={{ fontWeight: "bold" }}
              />
            )}
          </Space>
          <Space size={20} style={{ flexGrow: "1", justifyContent: "end" }}>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: LangOptions,
              }}
            >
              <Button
                shape="round"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0",
                  height: "unset",
                }}
              >
                <GlobalOutlined
                  style={{
                    fontSize: "20px",
                    display: "inline-flex",
                    backgroundColor: "#f4e6ff",
                    padding: "4px",
                    borderRadius: "35rem",
                    margin: "2px",
                  }}
                />
                {appConfig.direction === "ltr" ? "English" : "عربي"}
                <span style={{ margin: "0 8px" }}>
                  <DownOutlined
                    style={{
                      fontSize: "12px",
                    }}
                  />
                </span>
              </Button>
            </Dropdown>
            <Dropdown menu={{ items: UserOptions }} trigger={["click"]}>
              <Button
                shape="round"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0",
                  height: "unset",
                }}
              >
                <UserOutlined
                  style={{
                    fontSize: "20px",
                    display: "inline-flex",
                    backgroundColor: "#f4e6ff",
                    padding: "4px",
                    borderRadius: "35rem",
                    margin: "2px",
                  }}
                />
                {authUser.name + " "}
                <span style={{ margin: "0 8px" }}>
                  <DownOutlined
                    style={{
                      fontSize: "12px",
                    }}
                  />
                </span>
              </Button>
            </Dropdown>
          </Space>
        </Header>
      )}
      <Layout style={{ position: "relative" }}>
        {deviceWidth > 768 && !fullScreenPOS && (
          <Sider
            collapsible
            theme="light"
            trigger={null}
            defaultCollapsed={false}
            collapsed={isSiderCollapsed}
            collapsedWidth={150}
            width={220}
            style={{
              boxShadow: "#0000005c 0px 0px 30px -10px",
              overflow: "auto",
              position: "fixed",
              left: appConfig.direction === "ltr" ? 0 : "unset",
              right: appConfig.direction === "ltr" ? "unset" : 0,
              top: 65,
              bottom: 0,
            }}
          >
            {/* <Menu
              mode="vertical"
              className={isSiderCollapsed ? "custom-menu" : ""}
              selectedKeys={[activeMenu]}
            >
              {filterPagesByAddons(ContentPages, authUser).map(function (
                page,
                i
              ) {
                if (page.showInSider) {
                  return page.subMenus && page.subMenus.length > 0 ? (
                    <Menu.SubMenu
                      key={page.name}
                      icon={React.createElement(page.icon)}
                      title={t(page.text)}
                      disabled={blockRouting}
                    >
                      {filterPagesByAddons(page.subMenus, authUser).map(
                        function (subPage, j) {
                          return (
                            <Menu.Item
                              key={subPage.name}
                              onClick={() => saveCurrentPageView(subPage.name)}
                              icon={React.createElement(subPage.icon)}
                              className="custom-menu-popup"
                            >
                              <Link to={subPage.path}>{t(subPage.text)}</Link>
                            </Menu.Item>
                          );
                        }
                      )}
                    </Menu.SubMenu>
                  ) : (
                    <Menu.Item
                      key={page.name}
                      icon={React.createElement(page.icon)}
                      onClick={() => saveCurrentPageView(page.name)}
                      disabled={blockRouting}
                    >
                      <Link to={page.path}>{t(page.text)}</Link>
                    </Menu.Item>
                  );
                }
                return false;
              })}
            </Menu> */}
            <Menu
              mode="vertical"
              className={isSiderCollapsed ? "custom-menu" : ""}
              selectedKeys={[activeMenu]}
              items={filterPagesByAddons(ContentPages, authUser)
                .map((page) => {
                  if (page.showInSider) {
                    if (page.subMenus && page.subMenus.length > 0) {
                      return {
                        key: page.name,
                        icon: React.createElement(page.icon),
                        label: t(page.text),
                        disabled: blockRouting,
                        children: filterPagesByAddons(
                          page.subMenus,
                          authUser
                        ).map((subPage) => ({
                          key: subPage.name,
                          icon: React.createElement(subPage.icon),
                          label: (
                            <Link to={subPage.path}>{t(subPage.text)}</Link>
                          ),
                          onClick: () => saveCurrentPageView(subPage.name),
                          className: "custom-menu-popup",
                        })),
                      };
                    } else {
                      return {
                        key: page.name,
                        icon: React.createElement(page.icon),
                        label: <Link to={page.path}>{t(page.text)}</Link>,
                        onClick: () => saveCurrentPageView(page.name),
                        disabled: blockRouting,
                      };
                    }
                  }
                  return null;
                })
                .filter(Boolean)} // Filter out any null values that might have been returned
            />
          </Sider>
        )}
        <Layout
          style={{
            marginLeft:
              appConfig.direction === "ltr"
                ? isSiderCollapsed
                  ? fullScreenPOS
                    ? 0
                    : 150
                  : fullScreenPOS
                  ? 0
                  : 220
                : 0,
            marginRight:
              appConfig.direction === "ltr"
                ? 0
                : isSiderCollapsed
                ? fullScreenPOS
                  ? 0
                  : 150
                : fullScreenPOS
                ? 0
                : 220,
          }}
        >
          <Content
            style={{
              margin: "20px",
              padding: "0",
              background: "transparent",
              minHeight: fullScreenPOS
                ? "calc(100vh - 40px)"
                : "calc(100vh - 105px)",
            }}
          >
            {anyUnpaidInvoice && (
              <Alert
                message={t("unpaid_invoice")}
                description={t("pay_your_invoice")}
                type="error"
                showIcon
                action={
                  <Button
                    size="small"
                    danger
                    onClick={() => {
                      navigate("/invoices");
                      setActiveMenu(SIDEBAR_MENU_KEY_INVOICES);
                    }}
                  >
                    {t("show_invoices")}
                  </Button>
                }
              />
            )}

            {blockRouting ? (
              <>
                {showPageOnly === "STORE_CONFIGURATION" && (
                  <StoreConfiguration />
                )}
                {showPageOnly === "HOME" && <Home />}
                {showPageOnly === "SUBSCRIPTION" && <Subscriptions />}
                {showPageOnly === "INVOICES" && <Invoices />}
              </>
            ) : (
              <AppRoutes />
            )}
          </Content>
          {/* {!fullScreenPOS && (
            <Footer
              style={{
                textAlign: "center",
                backgroundColor: "#ffffff",
                fontWeight: "500",
                padding: "10px",
              }}
            >
              AnyPOS © {new Date().getFullYear()}
            </Footer>
          )} */}
        </Layout>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
