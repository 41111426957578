import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  InputNumber,
  Modal,
  Row,
  Spin,
  Table,
  Tag,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { EyeOutlined, HomeOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  INVENTORY_REQUEST_CANCELLED,
  INVENTORY_REQUEST_COMPLETED,
  INVENTORY_REQUEST_PENDING,
  INVENTORY_REQUEST_REJECTED,
  USER_TYPE_BUSINESS_OWNER,
} from "../../../configs/constants";
import moment from "moment";
import { AppContext } from "../../../context/AppContextProvider";
import {
  approveStockTransfersRequest,
  cancelRejectStockTransfersRequest,
  getStockTransfers,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";

const ViewTransfer = () => {
  const { appConfig, authUser } = useContext(AppContext);
  const [transfersData, setTransfersData] = useState({});
  const [reloadData, setReloadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { transferId } = useParams();

  useEffect(() => {
    setLoading(true);
    getStockTransfers()
      .then((response) => {
        setTransfersData(
          response.data.data.stock_transfers.find(
            (transfer) => transfer.id === Number(transferId)
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [transferId, reloadData]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/inventory/stock-transfer",
      breadcrumbName: t("menu_stock_transfer"),
      icon: <SettingOutlined />,
    },
    {
      path: "view_transfer",
      breadcrumbName: t("menu_view_transfer"),
      icon: <EyeOutlined />,
    },
  ];

  const handlePreAccept = (data) => {
    setModalData(data);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setModalData([]);
  };

  const handleCancelReject = (id) => {
    const formData = new FormData();
    if (id) {
      formData.append("id", id);
    }
    cancelRejectStockTransfersRequest(formData)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          // Success
          NotificationWithIcon(
            "success",
            t("transfer_updated"),
            t("transfer_updated_successfully")
          );
          setReloadData(!reloadData);
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      });
  };
  const handleAccept = () => {
    setLoading(true);
    let transactionObj = {
      id: modalData.id,
    };
    transactionObj.transfer_products = modalData.products.map((item) => ({
      transfer_product_id: item.id,
      approved_quantity: item.approved_quantity,
    }));
    // Sending Request to API
    approveStockTransfersRequest(transactionObj)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          // Success
          NotificationWithIcon(
            "success",
            t("transfer_updated"),
            t("transfer_updated_successfully")
          );
          setReloadData(!reloadData);
          setModalVisible(false);
          setModalData([]);
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("image"),
      width: "90px",
      render: (record) => {
        return (
          <>
            <img
              alt={record.product.name_en}
              src={
                record.product.image
                  ? record.product.image
                  : "/images/image-not-found.png"
              }
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          </>
        );
      },
    },
    {
      title: t("col_product"),
      render: (record) => {
        return (
          <>
            {appConfig.direction === "ltr"
              ? record.product.name_en
              : record.product.name}
          </>
        );
      },
    },
    {
      title: t("requested_quantity"),
      render: (record) => {
        return <>{record.requested_quantity}</>;
      },
    },
  ];
  const approvedColumn = [
    {
      title: t("approved_quantity"),
      render: (record) => {
        return <>{record.approved_quantity}</>;
      },
    },
  ];

  const adjustmentReducer = (data, quantity) => {
    let updatedAdjustment = JSON.parse(JSON.stringify(modalData));
    let productId = data.product.id;
    let indexFound = updatedAdjustment.products.findIndex(
      (product) => product.product.id === productId
    );
    updatedAdjustment.products[indexFound].approved_quantity = quantity;
    setModalData(updatedAdjustment);
  };

  const acceptingTableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("image"),
      width: "90px",
      render: (record) => {
        return (
          <>
            <img
              alt={record.product.name_en}
              src={
                record.product.image
                  ? record.product.image
                  : "/images/image-not-found.png"
              }
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          </>
        );
      },
    },
    {
      title: t("col_product"),
      render: (record) => {
        return (
          <>
            {appConfig.direction === "ltr"
              ? record.product.name_en
              : record.product.name}
          </>
        );
      },
    },
    {
      title: t("requested_quantity"),
      render: (record) => {
        return <>{record.requested_quantity}</>;
      },
    },
    {
      title: t("approved_quantity"),
      render: (record) => {
        return (
          <>
            <InputNumber
              style={{ width: "100px" }}
              min={0}
              onChange={(value) =>
                record.requested_quantity <= value
                  ? adjustmentReducer(record, record.requested_quantity)
                  : adjustmentReducer(record, value)
              }
              value={record.approved_quantity}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          title={t("menu_view_transfer")}
          onBack={() => navigate("/inventory/stock-transfer")}
          ghost={false}
          style={{ padding: "0" }}
        />
      </Card>
      <Spin spinning={loading}>
        <Card>
          {transfersData.id && (
            <>
              <Row gutter={[20, 20]}>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_date")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {transfersData.date_time
                      ? moment(transfersData.date_time).format("YYYY-MM-DD")
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_created_by")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {transfersData.created_by
                      ? transfersData.created_by.name
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_status")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {transfersData.status === INVENTORY_REQUEST_PENDING && (
                      <Tag color="orange">{t("pending")}</Tag>
                    )}
                    {transfersData.status === INVENTORY_REQUEST_COMPLETED && (
                      <Tag color="green">{t("completed")}</Tag>
                    )}
                    {transfersData.status === INVENTORY_REQUEST_CANCELLED && (
                      <Tag color="red">{t("cancelled")}</Tag>
                    )}
                    {transfersData.status === INVENTORY_REQUEST_REJECTED && (
                      <Tag color="red">{t("rejected")}</Tag>
                    )}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_branch_from")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {transfersData.branch_from
                      ? transfersData.branch_from.name
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_branch_to")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {transfersData.branch_to
                      ? transfersData.branch_to.name
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <Table
                    columns={
                      transfersData.status === INVENTORY_REQUEST_PENDING
                        ? [...tableColumns]
                        : [...tableColumns, ...approvedColumn]
                    }
                    dataSource={transfersData.products}
                    rowKey="id"
                    pagination={false}
                  />
                  {transfersData.status === INVENTORY_REQUEST_PENDING &&
                    (authUser.type === USER_TYPE_BUSINESS_OWNER ||
                      authUser.can_approve_stock_transfer) && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          alignItems: "center",
                          padding: "20px 0 0",
                          columnGap: "20px",
                        }}
                      >
                        {transfersData.branch_to.id === authUser.branch_id ? (
                          <>
                            <Button
                              type="primary"
                              danger
                              onClick={() =>
                                handleCancelReject(transfersData.id)
                              }
                            >
                              {t("cancel_transfer")}
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => {
                                navigate(
                                  "/inventory/stock-transfer/" +
                                    transfersData.id +
                                    "/edit",
                                  {
                                    state: transfersData,
                                  }
                                );
                              }}
                            >
                              {t("transfer_edit")}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="btn-success"
                              onClick={() => handlePreAccept(transfersData)}
                            >
                              {t("accept_transfer")}
                            </Button>
                            <Button
                              type="primary"
                              danger
                              onClick={() =>
                                handleCancelReject(transfersData.id)
                              }
                            >
                              {t("reject_transfer")}
                            </Button>
                          </>
                        )}
                      </div>
                    )}
                </Col>
              </Row>
            </>
          )}
        </Card>
      </Spin>
      <Modal
        open={modalVisible}
        title={t("accept_transfer")}
        onOk={handleAccept}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Table
          columns={acceptingTableColumns}
          dataSource={modalData.products}
          rowKey="id"
          pagination={false}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
            padding: "20px 0 0",
            columnGap: "20px",
          }}
        >
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button type="primary" loading={loading} onClick={handleAccept}>
            {t("confirm_transfer")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ViewTransfer;
