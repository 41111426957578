import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ImageUpload = ({ name, image, callbackSetImageFile }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof image === "string") {
      setImageUrl(image);
    } else if (typeof image === "boolean" && image === false) {
      setImageUrl("");
    }
    // console.log("RESET");
  }, [image]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t("validate_image_file_type"));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t("validate_image_size"));
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (imageUrl) => {
      setImageUrl(imageUrl);
      setLoading(false);
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t("upload")}</div>
    </div>
  );

  return (
    <Upload
      name={name}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      customRequest={(data) => {
        callbackSetImageFile(data.file);
      }}
    >
      {imageUrl ? (
        <img src={imageUrl} alt={name} style={{ width: "100%" }} />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default ImageUpload;
