import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Select,
  Row,
  Col,
  message,
  Tag,
  Switch,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  BarcodeOutlined,
  DeleteTwoTone,
  EditTwoTone,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  getProductCategories,
  getProductUnits,
  sendProductToOdoo,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import ImageUpload from "../../../components/ImageUpload";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { AppContext } from "../../../context/AppContextProvider";
import "./Products.css";
import { useTranslation } from "react-i18next";
import {
  ADDON_QR_ORDERING,
  ADDON_STOCK_MANAGEMENT,
  USER_TYPE_BUSINESS_OWNER,
  USER_TYPE_EMPLOYEE,
} from "../../../configs/constants";
import DownloadImportTemplate from "../../../components/DownloadImportTemplate";
import ImportItems from "../../../components/ImportItems";

const Products = () => {
  const ADD_PRODUCT = "Add Product";
  const { roundOff, appConfig, authUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [isTaxable, setIsTaxable] = useState(true);
  const [isQRproduct, setIsQRproduct] = useState(true);
  const [isStockableProduct, setIsStockableProduct] = useState(true);
  const [unitsList, setUnitsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [productsTableData, setProductsTableData] = useState([]);
  const [modalTitle, setModalTitle] = useState(ADD_PRODUCT);
  const [image, setImage] = useState(false);
  const [productForm] = Form.useForm();
  const { t } = useTranslation();

  const is_QR_addon_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_QR_ORDERING
  );

  const is_stock_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_STOCK_MANAGEMENT
  );

  useEffect(() => {
    getProductCategories().then((response) => {
      if (response.data.success === true) {
        setCategoriesList(response.data.data.product_categories);
      }
    });
    getProductUnits().then((response) => {
      if (response.data.success === true) {
        setUnitsList(response.data.data.product_units);
      }
    });
  }, []);

  useEffect(() => {
    setIsDataLoading(true);
    getProducts()
      .then((response) => {
        setProductsTableData(response.data.data.products);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const handleAdd = () => {
    productForm
      .validateFields()
      .then((values) => {
        // Set Form values as FormData
        const formData = new window.FormData();
        if (typeof image == "object" && image) {
          formData.append("image", image);
        }
        values.is_taxable = isTaxable ? 1 : 0;
        is_QR_addon_active && (values.is_qr_product = isQRproduct ? 1 : 0);
        is_stock_management_active &&
          (values.is_stockable = isStockableProduct ? 1 : 0);
        Object.keys(values).map((key) => {
          if (key === "price") {
            values[key] = roundOff(values[key]);
          }
          formData.append(key, values[key]);
          return true;
        });

        // Set Loading True
        setLoading(true);

        // Sending Request to API
        if (modalTitle === ADD_PRODUCT || modalTitle === t("product_add")) {
          createProduct(formData)
            .then((response) => handleAPIResponse(response, ADD_PRODUCT))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateProduct(formData)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    productForm.resetFields();
    // Reset Image Field
    setImage(false);
    // Hide Modal
    setModalVisible(false);
  };

  const showAddProductModal = () => {
    setModalTitle(t("product_add"));
    // Reset Form Fields
    productForm.resetFields();
    // Reset Image Field
    setImage(false);
    setIsTaxable(true);
    setIsQRproduct(false);
    setIsStockableProduct(true);
    setModalVisible(true);
  };

  const handleAPIResponse = (response, type = null) => {
    let data = response.data || response.response.data;
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_PRODUCT) {
        NotificationWithIcon(
          "success",
          t("product_added"),
          t("product_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("product_updated"),
          t("product_updated_successfully")
        );
      }
      // Reset Form Fields
      productForm.resetFields();
      // Reset Image Field
      setImage(false);
      // Hide Modal
      setModalVisible(false);
    } else {
      // Error
      let errorMessages = Object.values(data.errors);
      errorMessages.forEach(function (key, value) {
        let temp = Object.values(key);
        message.error(temp[0]);
      });
    }
  };

  const editProduct = (product_id) => {
    // Find Product from DataArray
    const product = productsTableData.find((prod) => prod.id === product_id);

    let product_name;

    appConfig.direction === "ltr"
      ? (product_name = product.name_en)
      : (product_name = product.name);

    // Set Modal Title
    setModalTitle(t("product_edit") + " : " + product_name);
    // Set Form Fields
    setIsTaxable(product.is_taxable);
    setIsQRproduct(product.is_qr_product);
    setIsStockableProduct(product.is_stockable);
    if (product.image) {
      setImage(product.image);
    } else {
      setImage("");
    }

    // Explicitly reset Form Fields before setting them for edit
    // This ensures the form is in a clean state before being repopulated
    productForm.resetFields();

    productForm.setFieldsValue(product);

    // Show Modal
    setModalVisible(true);
  };

  const deleteProductFunc = (id) => {
    deleteProduct(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("product_deleted"),
            t("product_deleted_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const handleSendToOdoo = (id) => {
    sendProductToOdoo(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("send_to_odoo"),
            t("item_send_to_odoo_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      });
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/products",
      breadcrumbName: t("menu_products"),
      icon: <BarcodeOutlined />,
    },
  ];

  const detailsPartColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("image"),
      render: (product) => {
        return (
          product && (
            <img
              alt={product.name_en}
              src={
                product.image ? product.image : "/images/image-not-found.png"
              }
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          )
        );
      },
    },
    {
      title: t("col_product"),
      render: (product) => {
        return (
          <>
            {product.name} <br />
            {product.name_en} <br />
            {product.is_qr_product && is_QR_addon_active && (
              <Tag color="blue">{t("is_QRproduct")}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("col_price"),
      dataIndex: "price",
      render: (price) => {
        return <CurrencyFormatter value={price} />;
      },
    },
    {
      title: t("col_category"),
      dataIndex: "category_ar",
    },
    {
      title: t("col_unit"),
      dataIndex: "unit_ar",
    },
  ];
  const odooStatusColumn = [
    {
      title: t("col_odoo_status"),
      align: "center",
      render: (record) => {
        return (
          <>
            {record.odoo_reference_id ? (
              <CheckOutlined style={{ fontSize: "22px", color: "#52c41a" }} />
            ) : (
              <>
                <CloseOutlined
                  style={{
                    fontSize: "22px",
                    color: "#ff0000",
                    display: "block",
                    paddingBottom: "5px",
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={() => handleSendToOdoo(record.id)}
                >
                  {t("send_to_odoo")}
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];
  const actionPartColumn = [
    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Button type="link" onClick={() => editProduct(record.id)}>
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            {authUser.type === USER_TYPE_BUSINESS_OWNER && (
              <>
                {is_stock_management_active && record.is_stockable ? (
                  <Popconfirm
                    title={t("warning_delete_stock_product")}
                    onConfirm={() => deleteProductFunc(record.id)}
                    okText={t("yes")}
                    cancelText={t("no")}
                  >
                    <DeleteTwoTone
                      twoToneColor="#ff0000"
                      title={t("delete_entity")}
                    />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title={t("warning_delete")}
                    onConfirm={() => deleteProductFunc(record.id)}
                    okText={t("yes")}
                    cancelText={t("no")}
                  >
                    <DeleteTwoTone
                      twoToneColor="#ff0000"
                      title={t("delete_entity")}
                    />
                  </Popconfirm>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_products")}
          ghost={false}
          extra={[
            authUser.can_add_product && (
              <Button
                key="open-add-product-modal"
                type="primary"
                onClick={showAddProductModal}
              >
                + {t("product_add")}
              </Button>
            ),
            authUser.type !== USER_TYPE_EMPLOYEE && (
              <DownloadImportTemplate type="product" key="import-template" />
            ),
            authUser.type !== USER_TYPE_EMPLOYEE && (
              <ImportItems
                type="product"
                callbackLoading={setReloadTableData}
                key="import-items"
              />
            ),
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={
            authUser.can_edit_product
              ? authUser.external_integrations.length
                ? [
                    ...detailsPartColumns,
                    ...odooStatusColumn,
                    ...actionPartColumn,
                  ]
                : [...detailsPartColumns, ...actionPartColumn]
              : authUser.external_integrations.length
              ? [...detailsPartColumns, ...odooStatusColumn]
              : [...detailsPartColumns]
          }
          dataSource={productsTableData}
          loading={isDataLoading}
          rowKey="name"
          pagination={false}
        />
      </Card>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAdd}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAdd}
          >
            {modalTitle === ADD_PRODUCT ? t("product_add") : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-product-form"
          layout="vertical"
          onFinish={handleAdd}
          style={{ width: "100%", margin: "0" }}
          form={productForm}
        >
          <div className="ant-col ant-form-item-label">
            <label title={t("image")}>{t("image")}</label>
          </div>

          <ImageUpload
            name="product_image"
            callbackSetImageFile={setImage}
            image={image}
          />

          <Form.Item
            name="name"
            label={t("name_ar")}
            rules={[
              {
                required: true,
                message: t("validate_product_name_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="name_en"
            label={t("name_en")}
            rules={[
              {
                required: true,
                message: t("validate_product_name_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="price"
            label={t("col_price")}
            rules={[
              {
                required: true,
                message: t("validate_product_price_required"),
              },
            ]}
            hasFeedback
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="category_id"
            label={t("col_category")}
            rules={[
              {
                required: true,
                message: t("validate_product_category_required"),
              },
            ]}
            hasFeedback
          >
            <Select>
              {categoriesList.map((category) => {
                return (
                  <Select.Option
                    key={"category-select-option-" + category.id}
                    value={category.id}
                  >
                    {appConfig.direction === "ltr"
                      ? category.name
                      : category.name_ar}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="unit_id"
            label={t("col_unit")}
            rules={[
              {
                required: true,
                message: t("validate_product_unit_required"),
              },
            ]}
            hasFeedback
          >
            <Select>
              {unitsList.map((unit) => {
                return (
                  <Select.Option
                    key={"unit-select-option-" + unit.id}
                    value={unit.id}
                  >
                    {appConfig.direction === "ltr" ? unit.name : unit.name_ar}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row>
            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
              <Form.Item name="is_taxable" label={t("is_taxable")}>
                <Switch
                  checked={authUser.is_vat_exempt ? false : isTaxable}
                  checkedChildren={t("yes")}
                  unCheckedChildren={t("no")}
                  onChange={(value) => {
                    setIsTaxable(value);
                  }}
                  disabled={authUser.is_vat_exempt}
                />
              </Form.Item>
            </Col>
            {is_QR_addon_active && (
              <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                <Form.Item name="is_qr_product" label={t("is_QRproduct")}>
                  <Switch
                    checked={isQRproduct}
                    checkedChildren={t("yes")}
                    unCheckedChildren={t("no")}
                    onChange={(value) => {
                      setIsQRproduct(value);
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            {is_stock_management_active && (
              <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                <Form.Item name="is_stockable" label={t("is_stockable")}>
                  <Switch
                    checked={isStockableProduct}
                    checkedChildren={t("yes")}
                    unCheckedChildren={t("no")}
                    onChange={(value) => {
                      setIsStockableProduct(value);
                    }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Products;
